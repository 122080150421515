<template>
  <div class="common-container  operation-container">
    <div class="common-content flex operation-wrapper">
      <div class="flex operation-box">
        <div class="operation-left">
          <div class="scroll-Y operation-left-box">
            <sub-menu
              v-if="operationList.length > 0"
              :list="operationList"
              :defaultId="defaultId"
              :openIds="openIds"
              @selectSub="selectSub"
            ></sub-menu>
            <div class="noData" v-else>暂无栏目</div>
          </div>
        </div>
        <div class="operation-right">
          <div class="flex search-box">
            {{ (chatData && chatData.name) || "" }}
            <div
              class="flex search-main"
              v-if="operationList.length > 0 && chatData && chatData.isContent"
            >
              <el-input
                v-model="keyWord"
                prefix-icon="el-icon-search"
                placeholder="请输入文章内容搜索"
                @keyup.enter.native="onSearch"
              ></el-input>
            </div>
          </div>
          <div
            class="scroll-Y scroll-X operation-main"
            v-loading="loading"
            element-loading-text="请稍等，文件加载中"
          >
            <template v-if="!loading && operationList.length > 0">
              <template
                v-if="
                  chatData && chatData.children && chatData.children.length > 0
                "
              >
                <div
                  class="operation-item"
                  v-for="(chart, idx) in chatData.children"
                  :key="idx"
                  :id="chart.id"
                >
                  <div class="title_one">
                    <div>{{ chart.chapterName || "" }}</div>
                    <div
                      class="title_one_view"
                      @click.stop="viewChart(chart.id)"
                    >
                      查看
                    </div>
                  </div>

                  <!-- <div class="content_two">
                    <div v-if="chart.isContent" v-html="chart.content"></div>
                    <template v-else>
                      <iframe
                        v-if="chart.fileType == 'pdf'"
                        :src="chart.content"
                        type="application/x-google-chrome-pdf"
                      />
                      <div
                        style="height:100%;"
                        v-else
                        :ref="'content' + chart.id"
                      ></div>
                    </template>
                  </div> -->
                </div>
              </template>
              <div class="content_two" v-else>
                <div v-if="chatData.isContent" v-html="chatData.content"></div>
                <template v-else>
                  <!-- <iframe
                    v-if="chatData.fileType == 'pdf'"
                    :src="chatData.content + '?page=hsn#toolbar=0'"
                    type="application/x-google-chrome-pdf"
                  /> -->
                  <!-- :src="chatData.content + '?page=hsn#toolbar=0'" -->
                  <div
                    v-if="chatData.fileType == 'pdf'"
                    style="position:relative;width:100%;height: 100%;"
                  >
                    <!-- <pdf-preview :url="chatData.content"></pdf-preview> -->
                    <iframe
                      :src="
                        'https://dendralink.com/pdf/#/?url=' + chatData.content
                      "
                    />
                    <!-- <div class="pdf-btn-box"></div> -->
                  </div>
                </template>
              </div>
            </template>
            <div class="noData" v-else>
              暂无内容
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import subMenu from "@/components/subMenu/index";
import { get, post } from "@/api/index";
// import pdfPreview from "@/components/pdfPreview";
export default {
  name: "operation",
  components: {
    subMenu
    //pdfPreview
  },
  data() {
    return {
      keyWord: "",
      operationIndex: 0,
      selectedId: "",
      defaultId: "",
      openIds: [],
      operationList: [],
      loading: true,
      chatData: null,
      oldContent: null
    };
  },
  created() {},
  computed: {},
  activated() {
    this.initData();
  },
  methods: {
    initData() {
      this.operationList = [];
      this.openIds = [];

      post(this.$setApi("/cus/help/doc/page/common"), {
        pageNum: 0,
        pageSize: 1000
      })
        .then(res => {
          console.log("re", res);
          this.loading = false;
          if (res.data && res.data.records && res.data.records.length > 0) {
            res.data.records.map((item, index) => {
              let data = {
                id: String(item.id),
                name: item.name,
                svgName: "czsc",
                content: item.content || "",
                contentTypeDictId: item.contentTypeDictId,
                contentType:
                  (item.contentType && item.contentType.dictLabel) || "",
                children: []
              };
              if (item.files && item.files.length) {
                data.fileList = item.files.map(ele => {
                  return {
                    id: ele.id,
                    name: ele.fileName,
                    url: ele.url,
                    address: ele.url
                  };
                });
              }
              if (index == 0) this.openIds.push(String(item.id));
              if (item.chapterList && item.chapterList.length > 0) {
                item.chapterList.map(it => {
                  if (index == 0) this.openIds.push(String(it.id));
                  data.children.push({
                    id: String(it.id),
                    name: it.chapterName,
                    content: it.content,
                    title: it.chapterName,
                    contentTypeDictId: it.contentTypeDictId,
                    fileList: []
                  });
                  if (it.files && it.files.length) {
                    data.fileList = it.files.map(e => {
                      return {
                        id: e.id,
                        name: e.fileName,
                        url: e.url,
                        address: e.url
                      };
                    });
                  }
                });
              }
              this.operationList.push(data);
            });
          }
          if (this.operationList.length > 0) {
            this.defaultId = this.operationList[0].id;
            if (this.operationList[0].children.length > 0) {
              this.defaultId = String(this.operationList[0].children[0].id);
              this.viewChart(this.operationList[0].children[0].id);
            } else this.getDetailData(this.operationList[0].id);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getDetailData(id) {
      this.loading = true;
      this.selectedId = id;
      get(this.$setApi("/cus/help/doc/") + id).then(res => {
        this.chatData = null;
        this.keyWord = "";
        this.oldContent = null;
        if (res.data) {
          this.chatData = res.data;
          this.chatData.isContent = true;
          this.chatData.fileType = "";
          if (
            this.chatData.contentType &&
            this.chatData.contentType.dictLabel &&
            this.chatData.contentType.dictLabel.indexOf("上传") > -1
          ) {
            if (this.chatData.files && this.chatData.files.length > 0) {
              let file = this.chatData.files[0];
              this.chatData.fileType = this.$getFileType(file.fileName);
              if (this.chatData.fileType == "pdf") {
                this.chatData.isContent = false;
                this.chatData.content = file.url;
              }
            }
          }
          this.loading = false;
        }
      });
    },

    onSearch() {
      if (this.chatData.content && this.chatData.fileType !== "pdf") {
        if (!this.oldContent)
          this.oldContent = JSON.parse(JSON.stringify(this.chatData.content));
        else this.chatData.content = this.oldContent;

        if (this.keyWord) {
          const reg = `/${this.keyWord}/g`;
          this.chatData.content = this.chatData.content.replace(
            eval(reg),
            "<span class='search-span' style='color: red;'>" +
              this.keyWord +
              "</span>"
          );
          this.$nextTick(() => {
            let element = document.getElementsByClassName("search-span");
            if (element && element.length > 0)
              element[0].scrollIntoView({ block: "start", behavior: "smooth" });
            else this.$message.warning("暂无搜索内容");
          });
        } else {
          this.chatData.content = JSON.parse(JSON.stringify(this.oldContent));
          this.oldContent = null;
        }
      } else this.$message.warning("暂无搜索内容");
    },
    goView(id) {
      if (id) document.getElementById(id).scrollIntoView();
    },
    selectSub(key, keyPath) {
      console.log("selectSub", key, keyPath, this.selectedId);
      if (keyPath.length > 1) this.viewChart(key);
      else this.getDetailData(keyPath[0]);
    },

    viewChart(id) {
      get(this.$setApi("/cus/help/doc/chapter/") + id).then(res => {
        this.chatData = null;
        this.keyWord = "";
        this.oldContent = null;
        if (res.data) {
          let item = res.data;
          this.chatData = res.data;
          this.chatData.name = item.chapterName;
          if (res.data.content) this.chatData.isContent = true;
          if (
            this.chatData.contentType &&
            this.chatData.contentType.dictLabel &&
            this.chatData.contentType.dictLabel.indexOf("上传") > -1
          ) {
            if (item.files && item.files.length > 0) {
              let file = item.files[0];
              item.fileType = this.$getFileType(file.fileName);
              if (item.fileType == "pdf") {
                this.chatData.isContent = false;
                this.chatData.content = file.url;
              }
            }
          }
        }
      });
    }
  },
  watch: {}
};
</script>
<style lang="stylus" scoped>
.operation-wrapper
  overflow hidden
  margin 0px auto
  height calc(100% - 0px)
  border-radius 8px

  .operation-box
    width 100%
    margin 30px 0
    box-sizing border-box

  .operation-left
    width 240px
    height 100%
    margin-right 24px
    border-radius 8px
    background #fff

    .zj-box
      position absolute
      top 0
      right -150px
      width 150px
      text-align center
      padding 0 16px
      background #d7d7d7
      padding-bottom 22px
      box-sizing border-box

      .zj-item
        height 29px
        margin-top 22px
        line-height 29px
        cursor pointer
        background rgba(242, 242, 242, 1)

    .operation-left-box
      overflow-x hidden
      padding 28px 10px
      height 100%
      >>>.el-menu-item
        height auto
        line-height 1.8
        span
          white-space: break-spaces
  .operation-right
    position relative
    height 100%
    background #fff
    border-radius 8px
    width calc(100% - 264px)

    .search-box
      justify-content space-between
      padding 0 24px
      height 72px
      align-items center
      font-size 24px
      color #222
      font-weight 600
      background #FFFFFF
      border-radius 8px 8px 0px 0px
      border 1px solid #EEEEEE

      .search-main
        align-items center
        height 46px
        width 216px

        >>>.el-input--small .el-input__inner
          height 46px
          line-height 46px
          border-radius 27px

        >>>.el-input--small .el-input__icon
          line-height 46px

    .operation-main
      height calc(100% - 94px)
      padding 24px 24px 0 24px
      overflow-y auto
      box-sizing border-box
      text-align left
      line-height 22px
      color #222

      .operation-item
        margin-bottom 24px

        &:last-children
          margin-bottom 0

      .chat-content
        margin-top 24px

        &:last-children
          margin-top 0

      .title_one
        display flex
        align-items center
        justify-content space-between
        font-size 18px
        font-weight 600

        .title_one_view
          font-size 14px
          cursor pointer
          color #999

      .title_two
        font-size 14px

      .content_two
        width 100%
        height 100%
        padding 8px 12px
        font-size 13px
        color #666

        >>>table, >>>img, >>>video
          width 100% !important

  .operation-txt-box
    position absolute
    top 0
    left 0
    width 100%
    height 100%

    .text-back
      cursor pointer

iframe
  width 100%
  min-height 400px
  height 100%
</style>
